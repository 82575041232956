import styles from './TermCard.module.scss';
import { Trash } from 'iconoir-react';
import { useRef, useState } from 'react';

function TermCard({ term = '', definition = '', ssid, index, onDragStart, onDragOver, onDrop, onDragEnd, onDelete, onChange }) {
    const cardRef = useRef(null);
    const [deleting, setDeleting] = useState(false);
    const [termValue, setTermValue] = useState(term);
    const [definitionValue, setDefinitionValue] = useState(definition);

    const handleTermChange = (e) => {
        const newTermValue = e.target.value;
        setTermValue(newTermValue);
        onChange(ssid, newTermValue, definitionValue);
    };

    const handleDefinitionChange = (e) => {
        const newDefinitionValue = e.target.value;
        setDefinitionValue(newDefinitionValue);
        onChange(ssid, termValue, newDefinitionValue);
    };

    return (
        <div
            className={`${styles.card} ${deleting ? styles.deleting : ''}`}
            data-ssid={ssid}
            ref={cardRef}
            onDragOver={(e) => onDragOver(e, ssid)}
            onDrop={(e) => {
                onDrop(e, ssid);
                cardRef.current.classList.remove(styles.dragging);
            }}
            onDragEnd={() => {
                onDragEnd();
                cardRef.current.classList.remove(styles.dragging);
            }}
        >
            <div
                className={styles.header}
                draggable
                onDragStart={(e) => {
                    onDragStart(e, ssid);
                    cardRef.current.classList.add(styles.dragging);
                }}
            >
                <span className={styles.ssid}>#{index}</span>
                <Trash 
                    onClick={() => {
                        onDelete(ssid);
                        setDeleting(true);
                    }}
                />
            </div>
            <div className={styles.body}>
                <input 
                    type="text" 
                    placeholder='Skriv term' 
                    value={termValue}
                    onChange={handleTermChange} 
                />
                <input 
                    type="text" 
                    placeholder='Skriv definition' 
                    value={definitionValue}
                    onChange={handleDefinitionChange} 
                />
            </div>
        </div>
    );
}

export default TermCard;