import styles from './TestCard.module.scss';
import { EditPencil, Play, Trash, ClipboardCheck } from 'iconoir-react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Popup from 'reactjs-popup';

function TestCard({ testId }) {
    const [ testData, setTestData ] = useState(null);

    useEffect(() => {
        axios.get(`https://api.theodorlundin.se/studian/test/get/data?test_id=${testId}`)
            .then(response => {
                setTestData(response.data.test);
            })
            .catch(error => {
                console.error('Error with API call: ', error.message);
            });
    }, [ testId ]);

    return (
        <div 
            className={styles.card} 
            tabIndex={0}
        >
            <div className={styles.body}>
                <div className={styles.header}>
                    <ClipboardCheck className={styles.icon} />
                    <div className={styles.titles}>
                    { testData ? 
                        <>
                            <span className={styles.title}>{ testData.name }</span>
                            <span className={styles.date}>Skapad { testData.created_readable }</span>
                        </>
                        : <Skeleton width={200} />
                    }
                    </div>
                </div>
                <div className={styles.actions}>
                    <div className={styles.liveActions}>
                        <Popup
                            trigger={<Play />}
                            position={'bottom center'}
                            on={['hover', 'focus']}
                            closeOnDocumentClick
                        >
                            <span style={{textAlign: 'center'}}>Starta förhör</span>
                        </Popup>
                    </div>
                    <div className={styles.testActions}>
                        <Popup
                            trigger={<EditPencil />}
                            position={'bottom center'}
                            on={['hover', 'focus']}
                            closeOnDocumentClick
                        >
                            <span style={{textAlign: 'center'}}>Redigera</span>
                        </Popup>
                        <Popup
                            trigger={<Trash />}
                            position={'bottom center'}
                            on={['hover', 'focus']}
                            closeOnDocumentClick
                        >
                            <span style={{textAlign: 'center'}}>Ta bort</span>
                        </Popup>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TestCard;