import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/account/Login';
import Signup from './pages/account/Signup';
import VerifyEmail from './pages/account/VerifyEmail';
import ForgotPassword from './pages/account/ForgotPassword';
import NewPassword from './pages/account/NewPassword';
import Explore from './pages/Explore';
import Integrity from './pages/Integrity';
import Conditions from './pages/Conditions';
import Dashboard from './pages/Dashboard';
import CreateSet from './pages/create/set/CreateSet';
import EditSet from './pages/edit/set/EditSet';
import CreateTest from './pages/create/test/CreateTest';
import Set from './pages/set/Set';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/new-password" element={<NewPassword />} />

        <Route path="/explore" element={<Explore />} />

        <Route path="/integrity" element={<Integrity />} />
        <Route path="/conditions" element={<Conditions />} />

        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/create/set" element={<CreateSet />} />
        <Route path="/edit/set/*" element={<EditSet />} />
        <Route path="/create/test" element={<CreateTest />} />
        <Route path="/edit/test/*" element={<EditSet />} />

        <Route path="/set/:set_id" element={<Set />} />
        <Route path="/set/:set_id/:type" element={<Set />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;