import styles from '../../create/set/CreateSet.module.scss';
import Navbar from "../../../components/navbars/Navbar";
import Auth from "../../../components/auth/Auth";
import ContainerSm from '../../../containers/ContainerSm';
import Section from '../../../containers/Section';
import CreateSetForm from "../../../components/pages/create/sets/CreateSetForm";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ErrorMessage from "../../../components/popups/message/ErrorMessage";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';

function EditSet() {
    const [setData, setSetData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);  // Loading state to manage API call

    // Get set data
    const setId = useLocation().pathname.split('/')[3] || '';
    
    useEffect(() => {
        setLoading(true);  // Set loading to true when API call starts
        axios.get(`https://api.theodorlundin.se/studian/set/get/data?set_id=${setId}`, {
            withCredentials: true
        }).then(response => {
            const termsArray = response.data.set.content.map(term => {
                return {
                    term: term[0],
                    definition: term[1]
                };
            });

            const data = {
                name: response.data.set.name,
                subject: response.data.set.subject,
                terms: termsArray
            };

            setSetData(data);  // Store the fetched set data
            setLoading(false);  // Set loading to false after data is loaded
        }).catch(error => {
            const message = error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!';
            setError(message);
            setLoading(false);  // Set loading to false even on error
        });
    }, [setId]);

    // Edit set data
    function handleUpdate(nameValue, subjectValue, termValues) {
        const data = {
            name: nameValue,
            subject: subjectValue,
            terms: termValues
        };

        setSetData(data);
    }

    function handleSubmit() {
        // Format a set object
        const termsArray = setData.terms.map(term => {
            return [term.term, term.definition];
        });

        // Call the API and update the set
        axios.post('https://api.theodorlundin.se/studian/set/manage/edit', {
            set_id: setId,
            name: setData.name,
            subject: setData.subject,
            content: termsArray
        }, {
            withCredentials: true
        }).then(() => {
            window.location.href = '/dashboard';
        }).catch(error => {
            const message = error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!';
            setError(message);
        });
    }

    return (
        <div>
            <Auth redirect="/login">
                <Navbar />
                <ContainerSm>
                    <Section>
                        <div className={styles.header}>
                            <h2>Redigera läxa</h2>
                            <PrimaryButton onClick={handleSubmit} disabled={loading}>Spara</PrimaryButton>
                        </div>
                        {error ? <ErrorMessage message={error} /> : null}
                        {loading ? (
                            <SyncLoader className={styles.loader} loading={loading} size={15} color={'#CACFDE'} />
                        ) : (
                            setData && (
                                <CreateSetForm
                                    onChange={handleUpdate}
                                    defaultName={setData.name}
                                    defaultSubject={setData.subject}
                                    defaultTerms={setData.terms}
                                />
                            )
                        )}
                    </Section>
                </ContainerSm>
            </Auth>
        </div>
    );
}

export default EditSet;