import { useCallback, useEffect, useState } from 'react';
import styles from './MatchTest.module.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PfpSmall from '../../user/PfpSmall';
import axios from 'axios';
import { ArrowLeftCircle, ArrowRightCircle, ArrowLeft } from 'iconoir-react';
import Auth from '../../auth/Auth';
import AuthNot from '../../auth/AuthNot';

function MatchTest({ setId, setData }) {
    const [ setName, setSetName ] = useState('');
    const [ termPairs, setTermPairs ] = useState([]);
    const [ terms, setTerms ] = useState([]);
    const [ started, setStarted ] = useState(false);
    const [ time, setTime ] = useState(0);
    const [ selectedFirst, setselectedFirst ] = useState(null);
    const [ allSelected, setAllSelected ] = useState([]);
    const [ matched, setMatched ] = useState([]);
    const [ done, setDone ] = useState(false);
    const [ topList, setTopList ] = useState([]);
    const [ topListPage, setTopListPage ] = useState(1);

    useEffect(() => {
        if (!setData) return;
        const mappedTerms = setData.content.map(pair => {
            return { term: pair[0], definition: pair[1] };
        });

        setSetName(setData.name);
        
        const shuffledTerms = shuffleArray(mappedTerms);
        const slicedTerms = shuffledTerms.slice(0, 6);

        setTermPairs(slicedTerms);

        const splittedTerms = [];
        for (let i = 0; i < slicedTerms.length; i++) {
            splittedTerms.push(slicedTerms[i].term);
            splittedTerms.push(slicedTerms[i].definition);
        }

        setTerms(shuffleArray(splittedTerms));
    }, [setData]);

    // Shuffle terms
    function shuffleArray(array) {
        let returnArray = [...array];
        let currentIndex = returnArray.length;

        while (currentIndex !== 0) {
            const randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [returnArray[currentIndex], returnArray[randomIndex]] = [returnArray[randomIndex], returnArray[currentIndex]];
        }

        return returnArray;
    }

    // Timer
    useEffect(() => {
        let interval = null;

        if (!started) {
            if (interval) clearInterval(interval);
            return;
        }
    
        interval = setInterval(updateTime, 100);
    
        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [started]);

    function updateTime() {
        setTime(currentTime => {
            const newTime = currentTime + 0.1;
            return Math.round((newTime + Number.EPSILON) * 10) / 10;
        });
    }

    // Check match
    function checkMatch(def) {
        let correct = false;
        
        termPairs.forEach(pair => {
            if ((pair.term === selectedFirst && pair.definition === def)
                || (pair.term === def && pair.definition === selectedFirst)) {
                setMatched(currentMatched => {
                    const newMatched = [...currentMatched, selectedFirst, def];
                    return newMatched;
                });

                correct = true;
            }
        });

        if (!correct) {
            setTime(current => current + 1);
        }

        setAllSelected([]);
        setselectedFirst(null);
    }

    // Click
    function handleClick(term) {
        if (!started) {
            setStarted(true);
        }

        setAllSelected(current => [...current, term]);

        if (!selectedFirst) {
            setselectedFirst(term);
        } else {
            if (term === selectedFirst) {
                setselectedFirst(null);
                setAllSelected([]);
            } else {
                checkMatch(term);
            }
        }
    }

    // End screens
    const endScreen = (
        <>
        <AuthNot>
            <div className={styles.endScreen}>
                <div className={styles.notAuthed}>
                    <span className={styles.time}>Din tid: { time.toFixed(1) }s</span>
                    <span className={styles.message}>Logga in för att komma med på topplistan!</span>
                    { renderTopList() }
                    <div className={styles.topListNav}>
                        <span className={styles.arrowButton}>
                            { topListPage > 1 && <ArrowLeftCircle className={styles.arrow} onClick={() => setTopListPage(topListPage - 1)} /> }
                        </span>
                        <span className={styles.pageNumber}>
                            { topList.length > 0 && `${topListPage} / ${Math.ceil(topList.length / 5)}` }
                        </span>
                        <span className={styles.arrowButton}>
                            { topListPage < Math.ceil(topList.length / 5) && <ArrowRightCircle className={styles.arrow} onClick={() => setTopListPage(topListPage + 1)} /> }
                        </span>
                    </div>
                </div>
            </div>
        </AuthNot>
        <Auth>
            <div className={styles.endScreen}>
                <div className={styles.notAuthed}>
                    <span className={styles.time}>Din tid: { time.toFixed(1) }s</span>
                    { renderTopList() }
                    <div className={styles.topListNav}>
                        <span className={styles.arrowButton}>
                            { topListPage > 1 && <ArrowLeftCircle className={styles.arrow} onClick={() => setTopListPage(topListPage - 1)} /> }
                        </span>
                        <span className={styles.pageNumber}>
                            { topList.length > 5 && `${topListPage} / ${Math.ceil(topList.length / 5)}` }
                        </span>
                        <span className={styles.arrowButton}>
                            { topListPage < Math.ceil(topList.length / 5) && <ArrowRightCircle className={styles.arrow} onClick={() => setTopListPage(topListPage + 1)} /> }
                        </span>
                    </div>
                </div>
            </div>
        </Auth>
        </>
    );

    // Top list
    // Load top list when setId or topListPage changes
    useEffect(() => {
        if (!setId) return;

        // API call to load top list
        axios.get(`https://api.theodorlundin.se/studian/set/match/get-top-list?set_id=${setId}`)
            .then(response => {
                setTopList(response.data.top_list);
            })
            .catch(error => {
                console.error('Error with API call: ', error.message);
            });
    }, [setId, topListPage]);

    // Top list rendering function (without API call)
    function renderTopList() {
        // Calculate indexes
        const startIndex = (topListPage - 1) * 5;
        const endIndex = topListPage * 5;
        const slicedList = topList.slice(startIndex, endIndex);
        
        // Render
        return (
            <div className={styles.topList}>
                { topList && slicedList.map((user, index) => (
                    <div className={styles.user} key={index}>
                        <span className={styles.username}>
                            <span className={styles.medal}>{ startIndex + index + 1 }</span>
                            <PfpSmall username={user.username} />
                            { user.username }
                        </span>
                        <span>{ user.time }s</span>
                    </div>
                ))}
            </div>
        );
    }

    const submitTime = useCallback(async () => {
        const auth = await axios.get('https://api.theodorlundin.se/studian/auth/auth', { withCredentials: true });
        if (auth.data?.authorized) {
            axios.post(`https://api.theodorlundin.se/studian/set/match/submit-time`, {
                set_id: setId,
                time: time
            }, {
                withCredentials: true
            }).then(() => {
                setTopListPage(1);
            })
            .catch(error => {
                console.error('Error with API call: ', error);
            });
        }
    }, [ setId, time ]);

    // Test done
    useEffect(() => {
        if (matched.length > 0 && matched.length === terms.length) {
            setStarted(false);
            setDone(true);
            submitTime();
        }
    }, [matched, terms, submitTime]);

    return (
        <div className={styles.container}>
            <div className={styles.testNav}>
                <ArrowLeft className={styles.back} onClick={() => window.location.href = `/set/${setId}`} />
                <span>Matcha: { setName }</span>
            </div>
            <div className={styles.test}>
                { !done &&
                    <>
                    <div className={styles.timer}>
                        <div className={styles.timerBar}>
                            <span>{ started ? time.toFixed(1) : 'Klicka på ett kort för att starta!' }</span>
                        </div>
                    </div>
                    <div className={styles.cardWrapper}>
                        { terms
                            ? terms.map((term, index) => {
                                return (
                                    <div 
                                        className={`${styles.card} ${allSelected.includes(term) ? styles.selected : ''} ${matched.includes(term) ? styles.matched : ''}`} 
                                        key={index} 
                                        onClick={() => { handleClick(term) }}
                                    >
                                        <span className={styles.term}>
                                            { started ? term : '?' }
                                        </span>
                                    </div>
                                );
                            })
                            : <Skeleton count={6} />
                        }
                    </div>
                    </>
                }
                { done && endScreen }
            </div>
        </div>
    );
}

export default MatchTest;