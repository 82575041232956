import styles from './CreateTestForm.module.scss';
import NormalInput from '../../../inputs/NormalInput';
import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import TestQuestion from './TestQuestion';
import SecondaryButton from '../../../buttons/SecondaryButton';
import { Plus } from 'iconoir-react';

const CreateTestForm = forwardRef(({ onChange, defaultName }, ref) => {
    const generateSSID = require('ssid');
    const [testName, setTestName] = useState(defaultName);
    const [questions, setQuestions] = useState([{ ssid: generateSSID(8) }]);
    const questionRefs = useRef([]);

    // Ensure that refs are created for each question when added
    if (questionRefs.current.length !== questions.length) {
        questionRefs.current = questions.map((_, i) => questionRefs.current[i] || React.createRef());
    }

    useImperativeHandle(ref, () => ({
        gatherQuestionsData: () => {
            return questionRefs.current.map(ref => ref.current?.getQuestionData()).filter(data => data !== undefined);
        },
        getTestName: () => testName
    }));

    const handleNameChange = (e) => {
        setTestName(e.target.value);
    };

    const handleAddQuestion = () => {
        setQuestions(current => [...current, { ssid: generateSSID(8) }]);
    };

    const handleRemoveQuestion = (index) => {
        setQuestions(current => {
            const newQuestions = [...current];
            newQuestions.splice(index, 1);
            // Also remove the corresponding ref
            questionRefs.current.splice(index, 1);
            return newQuestions;
        });
    };

    return (
        <div className={styles.form}>
            <div className={styles.inputWrapper}>
                <NormalInput
                    label="Namn"
                    title="Skriv namnet på läxförhöret"
                    placeholder="Skriv namnet på läxförhöret"
                    type="text"
                    onChange={handleNameChange}
                    value={testName}
                />
            </div>
            <div className={styles.questions}>
                <h3>Frågor</h3>
                <div className={styles.questionWrapper}>
                    {questions.map((question, index) => (
                        <TestQuestion
                            ref={questionRefs.current[index]}
                            key={question.ssid}
                            onRemove={() => handleRemoveQuestion(index)}
                        />
                    ))}
                </div>
                <div className={styles.buttonWrapper}>
                    <SecondaryButton onClick={handleAddQuestion}>
                        <Plus />
                        Lägg till fråga
                    </SecondaryButton>
                </div>
            </div>
        </div>
    );
});

export default CreateTestForm;