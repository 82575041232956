import styles from './TermCardWrapper.module.scss';
import TermCard from './TermCard';
import { useEffect, useState } from 'react';
import { Plus } from 'iconoir-react';
import SecondaryButton from '../../../buttons/SecondaryButton';
import ImportTermsModal from './ImportTermsModal';

function TermCardWrapper({ onChange, defaultTerms }) {
    const generateSSID = require('ssid');

    // Initialize terms with defaultTerms if they exist, otherwise, create an empty term.
    const [terms, setTerms] = useState(
        defaultTerms?.length > 0 
            ? defaultTerms.map(term => ({ ...term, ssid: generateSSID(8) }))
            : [{ ssid: generateSSID(8), term: '', definition: '' }]
    );
    
    const [dragging, setDragging] = useState(null);
    const [displayTerms, setDisplayTerms] = useState(terms);

    // Handle drag and drop logic
    function handleDragStart(e, ssid) {
        setDragging(ssid);
    }

    function handleDragOver(e, ssid) {
        e.preventDefault();
        if (ssid !== dragging) {
            const newTerms = terms.filter(term => term.ssid !== dragging);
            const index = newTerms.findIndex(term => term.ssid === ssid);
            newTerms.splice(index, 0, terms.find(term => term.ssid === dragging));
            setTerms(newTerms);
        }
    }

    function handleDrop(e, ssid) {
        setDragging(null);
    }

    function handleDragEnd() {
        setDragging(null);
    }

    // Handle drag and drop at the bottom (over buttons wrapper)
    function handleDragOverBottom(e) {
        e.preventDefault();
        if (dragging !== null) {
            const newTerms = terms.filter(term => term.ssid !== dragging);
            const index = newTerms.length;
            newTerms.splice(index, 0, terms.find(term => term.ssid === dragging));
            setTerms(newTerms);
        }
    }

    function handleDropAtBottom(e) {
        e.preventDefault();
        if (dragging !== null) {
            const newTerms = terms.filter(term => term.ssid !== dragging);
            newTerms.push(terms.find(term => term.ssid === dragging));
            setTerms(newTerms);
            setDragging(null);
        }
    }

    // Adding a new term
    function handleAddTerm() {
        const updatedTerms = [...terms, { ssid: generateSSID(8), term: '', definition: '' }];
        setTerms(updatedTerms);
        onChange(updatedTerms);  // Notify parent about the changes
    }

    // Placeholder for importing terms logic
    function handleImportTerms(termPairs) {
        const updatedTerms = termPairs.map(termPair => ({
            ssid: generateSSID(8),
            term: termPair.term,
            definition: termPair.definition
        }));
        setTerms(updatedTerms);
        onChange(updatedTerms);  // Notify parent about the changes
    }

    // Deleting a term
    function handleDeleteTerm(ssid) {
        const updatedTerms = terms.filter(term => term.ssid !== ssid);
        setTimeout(() => {
            setTerms(updatedTerms);
            onChange(updatedTerms);
        }, 350);  // Simulate a delay for the animation (if any)
    }

    // Update displayed terms and assign indices for proper ordering
    useEffect(() => {
        const updatedTerms = terms.map((term, index) => ({
            ...term,
            index: index + 1
        }));
        setDisplayTerms(updatedTerms);
    }, [terms]);

    // Handle changes to a term or its definition
    const handleChange = (ssid, termValue, definitionValue) => {
        const newTerms = terms.map(term => {
            if (term.ssid === ssid) {
                return { ...term, term: termValue, definition: definitionValue };
            }
            return term;
        });
        setTerms(newTerms);
        onChange(newTerms);  // Notify parent about the updated terms
    };

    return (
        <>
            <div className={styles.wrapper}>
                {displayTerms.map(({ ssid, term, definition, index }) => (
                    <TermCard
                        key={ssid}
                        ssid={ssid}
                        index={index}
                        term={term}
                        definition={definition}
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        onDragEnd={handleDragEnd}
                        onDelete={handleDeleteTerm}
                        onChange={handleChange}
                    />
                ))}
            </div>
            <div 
                className={styles.buttons}
                onDragOver={handleDragOverBottom}
                onDrop={handleDropAtBottom}
            >
                <ImportTermsModal onImport={handleImportTerms} />
                <SecondaryButton onClick={handleAddTerm}>
                    <Plus />
                    Lägg till term
                </SecondaryButton>
            </div>
        </>
    );
}

export default TermCardWrapper;