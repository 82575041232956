import { useEffect, useState } from 'react';
import styles from './WriteTest.module.scss';
import { Check, Xmark, ArrowLeft } from 'iconoir-react';
import 'react-loading-skeleton/dist/skeleton.css';
import PrimaryButton from '../../buttons/PrimaryButton';

function WriteTest({ setId, setData }) {
    const [ setName, setSetName ] = useState('');
    const [ input, setInput ] = useState('');
    const [ termIndex, setTermIndex ] = useState(0);
    const [ roundIndex, setRoundIndex ] = useState(0);
    const [ rounds, setRounds ] = useState([]);
    const [ lastIncorrectTerm, setLastIncorrectTerm ] = useState(null);
    const [ showingAnswer, setShowingAnswer ] = useState(false);
    const [ testDone, setTestDone ] = useState(false);

    useEffect(() => {
        if (!setData) return;
        const mappedTerms = setData.content.map(pair => {
            return { term: pair[0], definition: pair[1] };
        });

        setSetName(setData.name);
        
        const shuffledTerms = shuffleArray(mappedTerms);

        setRounds([
            {
                terms: shuffledTerms.map(term => term.term),
                defs: shuffledTerms.map(term => term.definition),
                progress: new Array(shuffledTerms.length).fill(null),
                answers: new Array(shuffledTerms.length).fill(null),
            }
        ]);
    }, [setData]);

    // Shuffle terms
    function shuffleArray(array) {
        let returnArray = [...array];
        let currentIndex = returnArray.length;

        while (currentIndex !== 0) {
            const randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [returnArray[currentIndex], returnArray[randomIndex]] = [returnArray[randomIndex], returnArray[currentIndex]];
        }

        return returnArray;
    }

    // Check answer
    function checkAnswer() {
        // Check if correct
        const isCorrect = input === rounds[roundIndex].terms[termIndex];

        // Show answer
        if (!isCorrect) {
            setShowingAnswer(true);
            setLastIncorrectTerm(rounds[roundIndex].terms[termIndex]);
        }

        // Update rounds
        setRounds(currentRounds => {
            const newRounds = [...currentRounds];

            // Update answers
            newRounds[roundIndex].answers[termIndex] = input;

            // Update progress
            if (isCorrect) {
                newRounds[roundIndex].progress[termIndex] = true;
            } else {
                newRounds[roundIndex].progress[termIndex] = false;

                if (!newRounds[roundIndex + 1]) {
                    newRounds[roundIndex + 1] = {
                        terms: [],
                        defs: [],
                        progress: [],
                        answers: [],
                    };
                }

                newRounds[roundIndex + 1].terms.push(rounds[roundIndex].terms[termIndex]);
                newRounds[roundIndex + 1].defs.push(rounds[roundIndex].defs[termIndex]);

                console.log('Added term to next round: ' + rounds[roundIndex].terms[termIndex]);
                console.log('Updated terms are:' + newRounds[roundIndex + 1].terms);
                console.log('-----');
            }

            return newRounds;
        });

        setInput('');
        nextTerm();
    }

    // Next term
    function nextTerm() {
        if (termIndex === rounds[roundIndex].terms.length - 1) {
            nextRound();
        } else {
            setTermIndex(termIndex + 1);
        }
    }

    // Next round
    function nextRound() {
        const newRoundIndex = roundIndex + 1;

        // Delay the test-done check until the state updates are complete
        setRounds(currentRounds => {
            const newRounds = [...currentRounds];

            // Check if the new round exists, and if not, end the test
            // The next round is not defined, because when roundIndex 
            // increases, but the current round is undefined, the test ends
            if (typeof newRounds[newRoundIndex] === 'undefined') {
                return newRounds;
            }

            // Initialize progress and answers for the new round
            newRounds[newRoundIndex].progress = new Array(newRounds[newRoundIndex].terms.length).fill(null);
            newRounds[newRoundIndex].answers = new Array(newRounds[newRoundIndex].terms.length).fill(null);

            return newRounds;
        });

        // After updating rounds, update roundIndex and reset termIndex
        setRoundIndex(newRoundIndex);
        setTermIndex(0);
    }

    // Watch for when the roundIndex updates to determine if the test should end
    useEffect(() => {
        if (roundIndex > 0 && rounds[roundIndex] === undefined) {
            handleTestDone();
        }
    }, [rounds, roundIndex]);

    // Reset input after answer is shown
    useEffect(() => {
        if (!showingAnswer) {
            setInput('');
        }
    }, [showingAnswer]);

    // Test done
    function handleTestDone() {
        setTestDone(true);
    }

    // Key events
    function handleKeyDown(e) {
        if (!showingAnswer) {
            if (e.key === 'Enter' || e.code === 'Enter') {
                e.preventDefault();
                checkAnswer();
            }
        } else {
            if (e.key === 'Enter' || e.code === 'Enter') {
                setShowingAnswer(false);
            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.testNav}>
                <ArrowLeft className={styles.back} onClick={() => window.location.href = `/set/${setId}`} />
                <span>Skrivtest: { setName }</span>
            </div>
            <div className={styles.test}>
                { !testDone &&
                    <><div className={styles.progress}>
                        {rounds[roundIndex]?.progress.map((progress, index) => {
                            let progressClass = '';
                            if (progress !== null) progressClass = progress ? styles.correct : styles.incorrect;

                            return (
                                <div className={`${styles.progressDiv} ${progressClass}`} key={index}></div>
                            );
                        })}
                    </div>
                    <span className={styles.definition}>
                        { showingAnswer ?
                            <i>Rätt svar: {lastIncorrectTerm ?? 'Laddar...'}</i>
                            : rounds[roundIndex]?.defs[termIndex] || 'Laddar...'
                        }
                    </span>
                    <input 
                        type="text" 
                        className={styles.input}
                        autoFocus 
                        autoCapitalize='off'
                        autoCorrect='off'
                        spellCheck='false'
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <div className={styles.buttonWrapper}>
                        <PrimaryButton onClick={checkAnswer}>Skippa</PrimaryButton>
                        <PrimaryButton onClick={checkAnswer}>Svara</PrimaryButton>
                    </div></>
                }
                { testDone &&
                    <><h2>Testet är klart!</h2>
                    <div className={styles.results}>
                        {rounds.map((round, index) => {
                            const correct = round.progress.map(progress => progress).length;
                            const total = round.terms.length;

                            return (
                                <div className={styles.round} key={index}>
                                    <strong>Runda {index + 1}: {correct.length}/{total}</strong>
                                    {round.terms.map((term, index) => {
                                        const correct = round.progress[index];

                                        return (
                                            <div className={`${styles.term} ${correct ? styles.correct : ''}`} key={index}>
                                                { correct ? <Check /> : <Xmark />}
                                                <span>{term}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div></>
                }
            </div>
        </div>
    );
}

export default WriteTest;